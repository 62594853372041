import NotFoundFeature from 'app/features/404'

import AuthContainer from '~/components/layouts/AuthContainer'

const NotFoundView = () => {
  return (
    <AuthContainer>
      <NotFoundFeature />
    </AuthContainer>
  )
}

export default NotFoundView
