import React from 'react'

import Box, { BoxProps } from '../Box'

export type CenterProps = {
  variant?: 'flex' | 'absolute'
} & BoxProps

const flexVariant = {
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
} as const

const absoluteVariant = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
} as const

const variants = {
  flex: flexVariant,
  absolute: absoluteVariant
}

const Center = ({ children, variant, sx, ...props }: CenterProps) => {
  const sxVariant = variants[variant || 'flex']

  return (
    <Box
      sx={{
        ...sxVariant,
        ...sx
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

export default Center
