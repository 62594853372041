import React from 'react'

import Box, { BoxProps } from 'app/components/base/Box'

type AuthContainerProps = BoxProps

const AuthContainer: RFC<AuthContainerProps> = ({ sx, children }) => {
  return (
    <Box
      sx={{
        mx: 'auto',
        mt: 8,
        mb: { xs: 7, md: 10 },
        maxWidth: '458px',
        width: '100%',
        ...sx
      }}
    >
      {children}
    </Box>
  )
}

export default AuthContainer
