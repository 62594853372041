import { FormattedMessage } from 'react-intl'
import { useRouter } from 'solito/router'

import Button from 'app/components/base/Button'
import Center from 'app/components/base/Center'
import Stack from 'app/components/base/Stack'
import Text from 'app/components/base/Text'
import { SEARCH_LINK } from 'app/utils/constants/menu.constants'

const NotFoundFeature = () => {
  const { push } = useRouter()

  return (
    <Center sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          px: { xs: 3, md: 0 }
        }}
      >
        <Text variant="h1" sx={{ fontSize: '64px' }}>
          <FormattedMessage defaultMessage="Oops!" />
        </Text>

        <Text color="text.third" sx={{ textAlign: 'center' }}>
          <FormattedMessage defaultMessage="Sorry, we couldn’t find this page. The page may no longer exist or the link could be incorrect." />
        </Text>

        <Button sx={{ maxWidth: 175, px: 1 }} onClick={() => push(SEARCH_LINK)}>
          <FormattedMessage defaultMessage="Go to Homepage" />
        </Button>
      </Stack>
    </Center>
  )
}

export default NotFoundFeature
