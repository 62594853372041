import React, { ReactElement } from 'react'

import MainLayout from '~/components/layouts/MainLayout'
import StaticPageTrianglesContainer from '~/components/layouts/StaticPageTrianglesContainer'
import NotFoundView from '~/views/404'

const NotFoundPage = () => {
  return <NotFoundView />
}

NotFoundPage.getLayout = (page: ReactElement) => (
  <MainLayout>
    <StaticPageTrianglesContainer
      sx={{
        display: 'flex',
        flex: 1,
        height: '100%'
      }}
    >
      {page}
    </StaticPageTrianglesContainer>
  </MainLayout>
)

NotFoundPage.getMeta = (intl) => ({
  title: intl.formatMessage({
    defaultMessage: 'Not Found'
  }),
  description: intl.formatMessage({
    defaultMessage: 'Not Found'
  })
})

export default NotFoundPage
