import dynamic from 'next/dynamic'
import { ReactNode } from 'react'

import { renderToStaticMarkup } from 'react-dom/server'

import Box, { BoxProps } from 'app/components/base/Box'

const TrianglesSVG = dynamic(
  () => import('app/assets/images/static-pages/static-pages-triangles.svg'),
  {
    ssr: false
  }
)

interface StaticPageTrianglesContainerProps {
  children: ReactNode
  sx?: BoxProps['sx']
}

const StaticPageTrianglesContainer: RFC<StaticPageTrianglesContainerProps> = ({
  children,
  sx
}) => {
  const svgString = encodeURIComponent(renderToStaticMarkup(<TrianglesSVG />))

  return (
    <Box sx={{ ...sx }}>
      <Box
        sx={{
          backgroundImage: `url("data:image/svg+xml,${svgString}")`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          boxShadow: 'rgba(255 255 255) 0px -50px 36px -25px inset',
          height: 400,
          width: '100%',
          position: 'absolute',
          zIndex: -1
        }}
      />

      {children}
    </Box>
  )
}

export default StaticPageTrianglesContainer
